<template>
  <v-dialog
    v-model="visible"
    class="org-modal"
    scrollable
    max-width="750"
  >
    <v-card
      v-if="action === 'delete'"
      class="pa-0"
    >
      <v-card-title primary-title>
        <v-row class="justify-space-between">
          <v-col class="col-grow subtitle-1 font-weight-bold darkestblue--text">
            Delete Organization
          </v-col>

          <v-col class="col-auto">
            <v-card-actions class="pa-0">
              <v-btn
                :ripple="false"
                icon
                @click.stop="close()"
              >
                <v-icon color="brightblue">
                  close
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-col class="mt-3 px-0 coolgray--text">
          <v-row>
            <v-col class="subtitle-2">
              ID
            </v-col>
            <v-col class="body-1">
              {{ entry.clientId }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Name
            </v-col>
            <v-col class="body-1">
              {{ entry.clientName }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Billing Code
            </v-col>
            <v-col class="body-1">
              {{ entry.billingCode }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Internal Use Only
            </v-col>
            <v-col class="body-1">
              {{ entry.isAdministrative ? 'TRUE' : 'FALSE' }}
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>

      <v-divider />

      <v-card-actions class="py-4 justify-end">
        <v-btn
          text
          color="coolgray"
          @click.stop="cancelAction"
        >
          Cancel
        </v-btn>

        <v-btn
          color="toasted"
          class="px-4 mr-4"
          dark
          @click.stop="submitOrgAction"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="['create', 'update'].includes(action)"
      class="pa-4"
    >
      <v-card-title>
        <span>
          {{ action === 'create' ? 'Add' : 'Edit' }} Organization
        </span>

        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close()"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="entry.clientName"
              label="Name"
              name="clientName"
              type="textual"
            />

            <v-text-field
              v-model="entry.billingCode"
              label="Billing Code"
              name="billingCode"
              type="textual"
            />

            <v-checkbox
              v-model="entry.isAdministrative"
              label="Internal Use Only"
              name="isAdministrative"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card-actions class="justify-end">
              <v-btn
                text
                color="coolgray"
                @click.stop="cancelAction"
              >
                Cancel
              </v-btn>

              <v-btn
                color="primary"
                @click.stop="submitOrgAction"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('internal')

export default {
  name: 'OrgModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    visible: {
      get() {
        return this.isOrganizationModalVisible
      },
      set(value) {
        this.$store.commit('internal/isOrganizationModalVisible', value)
      },
    },
    ...mapGetters(['errorMessage', 'successMessage']),
    ...mapState({
      action: (state) => state.action,
      entry: (state) => state.entry,
      didLoadOrganizationFail: (state) => state.didLoadOrganizationFail,
      isLoadingOrganization: (state) => state.isLoadingOrganization,
      isOrganizationModalVisible: (state) => state.isOrganizationModalVisible,
      organization: (state) => state.organization,
      organizations: (state) => state.organizations,
      selected: (state) => state.selected,
    }),
    viewOrganization() {
      const {
        organizations,
        didLoadOrganizationFail,
        isLoadingOrganization,
      } = this

      return isLoadingOrganization === false
        && didLoadOrganizationFail === false
        && organizations.length > 0
    },
    noOrganizationData() {
      const {
        organizations,
        isLoadingOrganization,
        didLoadOrganizationFail,
      } = this
      const requestComplete = isLoadingOrganization === false
      const requestSucceeded = didLoadOrganizationFail === false
      const noResults = organizations.length === 0

      return requestComplete && requestSucceeded && noResults
    },
  },
  watch: {
    selected(newValue) {
      if (newValue) {
        // do something
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('internal/isOrganizationModalVisible', false)
    },

    async submitOrgAction() {
      const { action, entry } = this
      const { commit, dispatch } = this.$store
      // @TODO: Actually map the action to what it's meant to be.
      const map = {
        create: 'ADD',
        update: 'UPDATE',
        delete: 'DELETE',
      }

      entry.actionType = map[action]

      const response = await dispatch('internal/submitOrgAction', entry)
      const { content = [], error } = response || {}

      if (error || !Array.isArray(content) || !response) {
        const message = error?.message || (content && (content.message || content.code)) || 'Unknown API Error'

        commit('tools/snackbar', true)
        commit('tools/snack', { message, status: 'error' })

        return error || content
      }

      const [entryId] = content
      const status = entryId ? 'success' : 'error'
      const message = entryId ? this.successMessage : this.errorMessage

      if (status === 'success') {
        this.close()
      }

      commit('tools/snackbar', true)
      commit('tools/snack', { message, status })

      return response
    },
    cancelAction() {
      const { selected } = this

      this.$store.commit('internal/action', 'read')
      this.$store.commit('internal/entry', Object.create(selected))

      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

::v-deep.v-card {
  border-radius: 6px;
  border-width: 0;
}

::v-deep.v-card__title {
  color: $color-monochrome-darkestblue;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.51px;
  line-height: 22px;
  justify-content: space-between;
}

::v-deep.v-card__text {
  padding: 0;
}

.v-card + .v-card {
  margin-top: 1ch;
}
</style>
